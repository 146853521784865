const config = {
  apiKey: "AIzaSyCRKlLcTfhgGION48riURKVBRJtqJkTyno",
  authDomain: "asteroid-dev-34509.firebaseapp.com",
  projectId: "asteroid-dev-34509",
  storageBucket: "asteroid-dev-34509.appspot.com",
  messagingSenderId: "579077119549",
  appId: "1:579077119549:web:ffbd94bc0044ea7aced454",
  measurementId: "G-JM2W7L5WK4",
}

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.js')
  } else {
    return config
  }
}