import './App.css'
import { Routes, Route } from 'react-router-dom'
import Login from './components/login.js'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="login" element={<Login />} ></Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem", fontSize: "2em" }}>
              <p>403</p>
            </main>
          }
        />
      </Routes>
    </div>
  )
}

export default App
