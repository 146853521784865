import React, { useState } from 'react'
// import { useParams } from "react-router-dom"
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
// import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirebaseConfig } from '../lib'

const app = initializeApp(getFirebaseConfig())
const auth = getAuth(app)
// const functions = getFunctions(app)
// const authCall = httpsCallable(functions, 'auth')

function Login() {
  // let { id } = useParams()
  const [message, setMessage] = useState('Loading ...')

  const handleSignin = async () => {
    try {
      console.log('sign')
      const email = document.getElementById('email').value
      const password = document.getElementById('password').value
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      const idToken =await userCredential.user.getIdToken()
      console.log('idToken ', idToken)
      // const result = await authCall({ token: idToken })
      window.location.href = `https://us-central1-asteroid-dev-34509.cloudfunctions.net/oidcProvider/authorize?token=${encodeURIComponent(idToken)}`
      // console.log('result: ', result)
      // setMessage(result.data.status)
    } catch (err) {
      console.log('error: ', err.message)
      setMessage('unauthenticated')
    }
  }

  return (
    <div className="App">
      <div>{message}</div>
      <input type="email" id="email" placeholder="Email"/>
      <input type="password" id="password" placeholder="Password"/>
      <button onClick={handleSignin}>Sign In</button>
    </div>
  )
}

export default Login